import { render, staticRenderFns } from "./YoutubeVideoModal.vue?vue&type=template&id=3a8dc7f5"
import script from "./YoutubeVideoModal.vue?vue&type=script&lang=js"
export * from "./YoutubeVideoModal.vue?vue&type=script&lang=js"
import style0 from "./YoutubeVideoModal.vue?vue&type=style&index=0&id=3a8dc7f5&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalWrapper: require('/usr/src/app/components/ModalWrapper.vue').default})
