
import YoutubeIcon from "~/assets/svg/heroicons/youtube.svg?inline";
import YoutubeVideoModal from "~/modals/YoutubeVideoModal";

export default {
  components: {
    YoutubeIcon,
  },
  props: {
    isMainBanner: {
      type: Boolean,
      default: false,
    },
    headlineTag: {
      type: String,
      default: "h4",
    },
    appearVisible: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      default: null,
    },
    subheadline: {
      type: String,
      default: null,
    },
    links: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      default: null,
    },
    imageAsBackground: {
      type: Boolean,
      default: false,
    },
    // @TODO: temporary, remove and use video
    backgroundVideo: {
      type: String,
      default: null,
    },
    video: {
      type: String,
      default: null,
    },
    colors: {
      type: Object,
      default: () => ({
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, #3a3939 100%)",
        text_color: "#FFFFFF",
      }),
    },
    productSection: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        background: this.colors.background,
        color: this.colors.text_color,
      };
    },
  },
  mounted() {
    if (process.browser) {
      this.$nextTick(() => {
        const videos = document.querySelectorAll("video");

        if (videos.length >= 1) {
          const options = {
            rootMargin: "0px 0px -20% 0px",
          };

          const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              // eslint-disable-line arrow-parens
              if (entry.isIntersecting) {
                entry.target.play();
              } else {
                entry.target.pause();
              }
            });
          }, options);

          Array.prototype.forEach.call(videos, (video) => {
            // eslint-disable-line arrow-parens
            observer.observe(video);
          });
        }
      });
    }
  },
  methods: {
    openVideoModal() {
      this.$modal.show(
        YoutubeVideoModal,
        {
          source: this.video,
        },
        {
          adaptative: true,
          maxWidth: 2560,
          width: "90%",
          height: "auto",
        }
      );
    },
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
