
import { mapGetters } from 'vuex'

const curatorFeeds = {
  // International
  default: {
    id: 'curator-feed-default-feed-layout',
    src:
      'https://cdn.curator.io/published/55f41547-da22-4116-840c-780ed712490d.js'
  },
  // Algeria
  dz: {
    id: 'curator-feed-keeway-algeria-layout',
    src:
      'https://cdn.curator.io/published/ff0ef03c-184d-4151-821c-2ca75f5a8265.js'
  },
  // Argentina
  ar: {
    id: 'curator-feed-keeway-argentina-layout',
    src:
      'https://cdn.curator.io/published/eec4c36a-a64e-431e-a54a-40a69ae1afa6.js'
  },
  // Bolivia
  bo: {
    id: 'curator-feed-keeway-bolivia-layout',
    src:
      'https://cdn.curator.io/published/37a07352-4116-437c-8831-2deaafa83b81.js'
  },
  // Cambodia
  kh: {
    id: 'curator-feed-new-feed-layout1',
    src:
      'https://cdn.curator.io/published/abf73630-ef60-4263-a1bf-50c91fd9a686.js'
  },
  // Chile
  cl: {
    id: 'curator-feed-keeway-chile-layout',
    src:
      'https://cdn.curator.io/published/f4b491cb-7629-4c49-895f-d82e1754221b.js'
  },
  // Ecuador
  ec: {
    id: 'curator-feed-keeway-ecuador-layout',
    src:
      'https://cdn.curator.io/published/92160a9a-5e6c-4c99-ae1e-ce020fd4683e.js'
  },
  // El Salvador
  sv: {
    id: 'curator-feed-new-feed-layout2',
    src:
      'https://cdn.curator.io/published/a74404bb-746b-4af2-89fe-a8bc433b9021.js'
  },
  // France
  fr: {
    id: 'curator-feed-keeway-france-layout',
    src:
      'https://cdn.curator.io/published/c215d187-6589-4b9b-a9fd-e12e8bea14a7.js'
  },
  // Germany
  de: {
    id: 'curator-feed-keeway-germany-layout',
    src:
      'https://cdn.curator.io/published/9f9e2772-eca6-48ea-8df1-5a96aba765fc.js'
  },
  // Greece
  gr: {
    id: 'curator-feed-keeway-greece-layout',
    src:
      'https://cdn.curator.io/published/14f05433-d313-4df7-b327-b504e0c17e26.js'
  },
  // Hungary
  hu: {
    id: 'curator-feed-new-feed-layout3',
    src:
      'https://cdn.curator.io/published/3cd32e8b-c6d3-40e9-9aed-8fd9db94dcd8.js'
  },
  // India
  in: {
    id: 'curator-feed-keeway-india-layout1',
    src:
      'https://cdn.curator.io/published/54b8993f-20cd-432f-a015-0d9455847b30.js'
  },
  // Indonesia
  id: {
    id: 'curator-feed-keeway-indonesia-layout',
    src:
      'https://cdn.curator.io/published/1d112851-58ab-41bd-8e86-a36cf36f1767.js'
  },
  // Italy
  it: {
    id: 'curator-feed-keeway-italy-layout',
    src:
      'https://cdn.curator.io/published/42ce7980-d18b-4f23-af71-9a112908f1eb.js'
  },
  // Malaysia
  my: {
    id: 'curator-feed-keeway-malaysia-layout',
    src:
      'https://cdn.curator.io/published/d0cb37df-b101-4a9d-ac2e-5eab355ae2c7.js'
  },
  // Panama
  pa: {
    id: 'curator-feed-keeway-panama-layout',
    src:
      'https://cdn.curator.io/published/67a0def0-3f08-416f-9c00-8b6ade7bc7e5.js'
  },
  // Peru
  pe: {
    id: 'curator-feed-keeway-peru-layout',
    src:
      'https://cdn.curator.io/published/fe9b3348-b958-499d-8aac-dc80d2eee0af.js'
  },
  // Philippines
  ph: {
    id: 'curator-feed-keeway-philippines-layout',
    src:
      'https://cdn.curator.io/published/2663d349-542e-4092-bec0-4635daa3c8b9.js'
  },
  // Poland
  pl: {
    id: 'curator-feed-keeway-poland-layout',
    src:
      'https://cdn.curator.io/published/94fb8b0c-95d9-4884-a5bc-46ebc23edb96.js'
  },
  // Portugal
  pt: {
    id: 'curator-feed-keeway-india-layout',
    src:
      'https://cdn.curator.io/published/00675584-d05a-4939-9596-0bc3acf94588.js'
  },
  // Spain
  es: {
    id: 'curator-feed-keeway-spain-layout',
    src:
      'https://cdn.curator.io/published/dddfc036-cde9-4446-b79e-5e4409afbd5b.js'
  },
  // Thailand
  th: {
    id: 'curator-feed-keeway-thailand-layout',
    src:
      'https://cdn.curator.io/published/d6a5243b-537b-4665-beaf-4f1f811dcc3a.js'
  },
  // Tunisia
  tn: {
    id: 'curator-feed-keeway-tunisia-layout',
    src:
      'https://cdn.curator.io/published/32e886d4-6e5b-4055-954d-086a4fc08e7e.js'
  },
  // United Kingdom
  gb: {
    id: 'curator-feed-keeway-uk-layout',
    src:
      'https://cdn.curator.io/published/6fc89700-4b92-49d7-a551-cdc578b994f3.js'
  },
  // United States
  us: {
    id: 'curator-feed-keeway-usa-layout',
    src:
      'https://cdn.curator.io/published/dc192bc9-7cef-445e-b60d-a9de91ddafa8.js'
  },
  // Uruguay
  uy: {
    id: 'curator-feed-keeway-uruguay-layout',
    src:
      'https://cdn.curator.io/published/d156bfe1-708c-4da0-b70f-c7434ce4c0a8.js'
  },
  // Romania
  ro: {
    id: "curator-feed-keeway-romania-layout",
    src: "https://cdn.curator.io/published/b707087b-309b-4afe-b8cd-453abefef15c.js",
  },
}
export default {
  data() {
    return {
      curatorSettings: curatorFeeds[this.$store.state.country]
        ? curatorFeeds[this.$store.state.country]
        : curatorFeeds.default
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'getCurrentSite'
    })
  },
  head() {
    const scripts = []
    if (process.browser && this.currentSite.settings.curator) {
      scripts.push({
        // class: '_iub_cs_activate',
        // 'data-suppressedsrc': curatorFeeds[this.$store.state.country] ? curatorFeeds[this.$store.state.country].src : curatorFeeds.default.src
        // 'data-iub-purposes': 3,
        crossorigin: true,
        defer: true,
        // type: 'text/plain'
        src: curatorFeeds[this.$store.state.country] ? curatorFeeds[this.$store.state.country].src : curatorFeeds.default.src
      })
    }

    return {
      script: scripts
    }
  }

}
